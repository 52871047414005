import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

import { Account, CommonQuery, User, UserUpData, UserVerification } from '@/interfaces';

class ApiUser extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/user'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  verification(data: UserVerification) {
    return this.patch<User>('/verification', data);
  }

  searchUsers(data: string) {
    return this.get<User[]>(`/search-users?search=${data}`);
  }

  getUser(_id: string) {
    return this.get<User>(`/get-user?_id=${_id}`);
  }

  updateRegionUser(region: string) {
    return this.patch<User>('/region-user', { region });
  }

  getList(params: CommonQuery) {
    return this.get<User[]>('/list', { params });
  }

  getItem(_id: string) {
    return this.get<{ user: User; accounts: Account[] }>(`/${_id}`);
  }

  updateUser(data: UserUpData) {
    return this.patch<User>('/update', data);
  }


}

export const apiUser = new ApiUser();
