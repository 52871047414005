
export default {
  title: 'История заказов',
  search: 'Поиск...',
  searchUsers: 'Найти пользователя',
  searchTransaction: 'Найти транзакцию',
  order: 'Заказ',
  deleteOrder: 'Удалить заказ',
  canceled: 'Отменить',
  total: 'Итого:',
  flowers: 'Цветы',
  paid: 'Оплачено',
  forPayment: 'К оплате',
  cancel: 'Отмена',
  apply: 'Применить',
  edit: 'Редактировать',
  delivery: 'Доставка',
  toReturn: 'К возврату',
  saveDeliveryMethod: 'Сохранить',
  pay: 'Оплатить',
  saveStatus: 'Сохранить',
  user: 'Пользователь',
  downloadUPD: 'Скачать УПД',

  address: 'Адрес',
  city: 'Город',
  country: 'Страна',
  company: 'Компания',
  phone: 'Тел',
  name: 'Имя',
  surname: 'Фамилия',
  index: 'Индекс',
  inn: 'ИНН',

  new: 'Новый',
  confirmed: 'Подтвержден',
  assembled: 'Собран',
  shipped: 'Отгружен',
  delivered: 'Доставлен',
  return: 'Возврат',

  useHead: {
    historyTitle: 'История заказов', 
    orderTitle: 'Заказ',
  },
  history: {
    height: 'Рост:',
    cm: 'см',
    quantity: 'Количество:',
    pc: 'шт.',
    totalCost: 'Общая стоимость:',
    deliveryFrom: 'Доставка с:',
    pricePerPiece: 'Цена за шт:',
  },
  edited: {
    height: 'Рост:',
    cm: 'см',
    price: 'Цена:',
    flower: 'цветок',
    forAll: 'за все',
    flowers: 'цветков',
    inStock: 'В наличии:',
  },
  notification: {
    emptyOrder: 'Нельзя сохранить пустой заказ',
    emptyPos: 'Удалите позиции, у которых остался нулевой остаток',
    error: 'Произошла непредвиденная ошибка',
    confirmDel: 'Вы уверены, что хотите удалить этот заказ',
    confirmCancel: 'Вы уверены, что хотите отменить этот заказ',
  },

}
