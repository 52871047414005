export default {
  catalog: 'Каталог',
  assortment: 'Ассортимент',
  transactions: 'Транзакции',
  operationsHistory: 'История операций',
  operationsProducts: 'Операции',
  transfers: 'Переводы',
  exchange: 'Биржа',
  auction: 'Аукцион',
  information: 'Информация',
  verification: 'Верификация',
  replenishments: 'Пополнения',
  topUpBalance: 'Пополнить баланс',
  betweenYourAccounts: 'Между своими счетами',
  nameless: 'Безымянный',
  profile: 'Профиль',
  historyOfOrders: 'История заказов',
  historyBalance: 'История баланса',
  logout: 'Выйти',
  payments: 'Покупки',
  acceptance: 'Приемка',
  writeoff: 'Списание',
  margin: 'Маржа',
  delivery: 'Доставка',
  settings: 'Настройки',
  region: 'Регион',
  users: 'Пользователи',
  rates: 'Курс',

  QRCODE: 'QR-код Tinkoff',
  CRYPTO: 'Криптовалюта',
  CASH: 'Оплата наличными',
  MANAGER: 'Внесено менеджером',
  CARD: 'Оплата картой',
  BALANCE: 'Оплата с баланса',
  USER: 'Оплата пользователем',
  ADMIN: 'Внесено админом',
  CHECK: 'Оплата счетом',
  IN_PROCESSED: 'В работе',
  REJECTED: 'Отменено',
  CONFIRMED: 'Пополнено',
  CONCLUSION: 'Списано',
  COMPENSATION: 'Компенсация',


}

