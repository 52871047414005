import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

import { Account, AccountRate, UserUpDataBalance } from '@/interfaces';

class ApiAccount extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/account'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  getUserList(_id: string) {
    return this.get<Account[]>(`/list/${_id}`);
  }

  getList() {
    return this.get<Account[]>('/list');
  }

  updateAccount(data: UserUpDataBalance) {
    return this.patch<Account[]>('/update', data);
  }

  getRates(amount: string, recipient: string, source: string, target: string, isSended: boolean, isCommission: boolean) {
    return this.get<{ value: number, course: number } | false>('/rates', {
      params: { amount, source, target, recipient, isSended, isCommission },
    });
  }
}

export const apiAccount = new ApiAccount();
