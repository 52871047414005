import { CommonQuery, QueryType, Transaction } from '@/interfaces';
import { apiTransaction } from '@/services';

export const useTransactionStore = defineStore('transaction', () => {
  const transactionList = ref<Array<Transaction>>([]);
  const isHasMore = ref(true)

  const initialQuery = {
    page: 1,
    limit: 20,
  };
  
  const query = ref<QueryType & CommonQuery>({
    ...initialQuery,
  });
  
  function resetQuery() {
    query.value = { ...initialQuery };
  }

  async function getTransactionList() {
    return apiTransaction.getList(query.value)
      .then(({ data }) => {
        if (query.value.page === 1) {
          transactionList.value = data;
        } else {
          transactionList.value = transactionList.value.concat(data)
        }

        isHasMore.value = !!data.length
      })
      .catch(() => {
        useNotificationStore().create({
          type: 'error',
          message: 'Что-то пошло не так',
        })
      })
  }

  return {
    transactionList,
    isHasMore,
    query,
    getTransactionList,
    resetQuery,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTransactionStore, import.meta.hot));
}
