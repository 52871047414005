import { apiAuth } from "@/services";
import Decimal from "decimal.js";

 
export const methodDeliveryGlobal = reactive({
  dates: {} as Record<string, 'pickup' | 'delivery'>,
  deliveryСity: computed(() => {
    try {
      const userStore = useUserStore();
      return userStore.getRegionDeliveryСity ?? 1000
    } catch (error) {
      console.log(error);
      return 1000
    }
  }),
});


export const useCartStore = defineStore('cart', () => {
  const runtimeConfig = useRuntimeConfig();
  const cart: any = ref({}); // { [key: date]: flowers[] }
  const getCart = computed(() => cart.value);

  const getCartLength = computed(() => {
    let sum = 0;
    for (let key in cart.value) {
      if (Array.isArray(cart.value[key])) {
        sum += cart.value[key].length;
      }
    }
    return sum;
  });

  const getTotalPrice = computed(() => {
    const value = Object.keys(cart.value).reduce((acc, next) => {
      acc += cart.value[next].reduce((acc: any, next: any) => {
        acc += new Decimal(parseFloat(formatPrice(next.price))).mul(next.quantity).toNumber();
        return acc;
      }, 0);
      if (!methodDeliveryGlobal.dates[next]) methodDeliveryGlobal.dates[next] = 'delivery'
      const deliveryСity = methodDeliveryGlobal.deliveryСity
      cart.value[next].deliveryCost = acc < 10000 && methodDeliveryGlobal.dates[next] === 'delivery' ? deliveryСity : 0
      return acc;
    }, 0);
    return Number(value.toFixed(2));
  });

  const getTotalDeliveryCost = computed(() => {
    return Object.keys(cart.value).reduce((acc, cur) => {
      return acc += cart.value[cur].deliveryCost ?? 0
    }, 0)
  })

  function addToCart(date: string, dto: any) {
    if (cart.value[date]) {
      cart.value[date].push(dto);
    } else {
      cart.value[date] = [dto];
    }
    localStorage.setItem('cart', JSON.stringify(cart.value));
  }

  function replaceCart(newCart: any) {
    cart.value = newCart;
    localStorage.setItem('cart', JSON.stringify(newCart));
  }

  function compareCarts(newCart: any) {
    return JSON.stringify(cart.value) === JSON.stringify(newCart);
  }

  function initCart() {
    const parsedCart = JSON.parse(localStorage.getItem('cart') ?? '{}');
    if (parsedCart) {
      cart.value = parsedCart;
    }
  }

  function removeFromCart(date: string, dto: any) {
    const objWithIdIndex = cart.value[date].findIndex((obj: any) => obj.code === dto);
    if (objWithIdIndex > -1) {
      cart.value[date].splice(objWithIdIndex, 1);
      if (!cart.value[date].length) {
        delete cart.value[date];
      }
    }
    localStorage.setItem('cart', JSON.stringify(cart.value));
  }

  function clearCart() {
    cart.value = {};
    localStorage.setItem('cart', '{}');
    methodDeliveryGlobal.dates = {}
  }

  async function checkCart() {
    const auth = await apiAuth.authentication()
    const url = auth?.data?.region
      ? `/api/moysklad/check-cart?region=${auth.data.region}`
      : '/api/moysklad/check-cart'

    return $fetch(runtimeConfig.public.apiUrl + url, {
      method: 'POST',
      body: cart.value,
    });
  }

  return {
    cart,
    getCart,
    getCartLength,
    getTotalPrice,
    getTotalDeliveryCost,
    addToCart,
    removeFromCart,
    clearCart,
    initCart,
    checkCart,
    replaceCart,
    compareCarts,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
