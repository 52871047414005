import { defineStore } from 'pinia';
import socket from '@/socket';
import { apiAuth, apiUser } from '@/services';
import { UserVerificationStatus, type User, UserVerification, UserRole, QueryType, CommonQuery } from '@/interfaces';

export const useUserStore = defineStore('user', () => {
  const runtimeConfig = useRuntimeConfig();

  const userList = ref<Array<User>>([]);
  const isHasMore = ref(true)

  const query = ref<QueryType & CommonQuery>({
    page: 1,
    limit: 20,
  })


  const user = ref({} as User);
  const is = reactive({
    auth: false,
    init: false,
    socket: false,
  });

  const setUser = (value: User) => (user.value = value);
  const setIsSocket = (value: boolean) => (is.socket = value);
  const setIsInit = (value: boolean) => (is.init = value);
  const setIsAuth = (value: boolean) => (is.auth = value);
  const setId = (value: string) => (user.value._id = value);
  const setAvatar = (value: string) => (user.value.avatar = value);
  const setVerification = (value: UserVerification) => (user.value.verification = value);

  const getters = {
    getRegionDeliveryDay: computed(() => user.value.deliveryDay),
    getRegionDeliveryСity: computed(() => user.value.deliveryСity),
    getRegion: computed(() => user.value.region),
    getIsAuth: computed(() => is.auth),
    getIsSocket: computed(() => is.socket),
    getIsInit: computed(() => is.init),
    getId: computed(() => user.value._id),
    getUser: computed(() => user.value),
    getEmail: computed(() => user.value.email),
    getAvatar: computed(() => user.value.avatar),
    getRole: computed(() => user.value.role),
    getVerification: computed(() => user.value.verification),
    getVerificationComment: computed(() => user.value.verification.comment),
    getName: computed(() => user.value.verification?.name),
    getPhone: computed(() => user.value.verification?.phone),
    getVerificationStatus: computed(
      () => user.value.verification?.status || UserVerificationStatus.INITIAL,
    ),
    isApprovedVerification: computed(
      () => user.value.verification?.status === UserVerificationStatus.APPROVED,
    ),
    getFullAddress: computed(
      () =>
        `${user.value.verification?.city} ${user.value.verification?.address} ${user.value.verification?.index}`,
    ),
  };

  const updateDataUser = async () => {
    try {
      const auth = await apiAuth.authentication()
      if (auth && auth.data && auth.data._id) {
        setUser(auth.data);
        setIsInit(true);
        return true
      }
      return false
    } catch (error) {
      console.log(error);
      return false
    }
  };

  const initUser = async () => {
    if (is.init) return user.value;

    return apiAuth.authentication()
      .then(({ data }) => {
        if (data.role === UserRole.ADMIN && !import.meta.env.DEV && process.client) {
          return location.replace(import.meta.env.VITE_BASE_ADMIN || '');
        }

        setUser(data);
        setIsInit(true);
        return data;
      })
      .catch(err => {
        console.error(err)
        return false;
      })
  };

  const connectSocket = () => {
    if (is.socket) return;
    setIsSocket(true);

    socket.init();
  };

  const logout = async () => {
    apiAuth.logout().finally(() => {
      location.reload();
    });
  };

  async function confirmPhone(phone: string) {
    return await $fetch(runtimeConfig.public.apiUrl + '/api/zvonok/confirm-phone', {
      method: "POST",
      credentials: 'include',
      body: {
        _id: user.value._id,
        phone: phone.replaceAll(/[^\d]/g, ''),
      }
    })
  }

  async function sendPin(code: string) {
    return await $fetch(runtimeConfig.public.apiUrl + '/api/zvonok/send-pin', {
      method: "POST",
      credentials: 'include',
      body: {
        _id: user.value._id,
        pinCode: code,
      }
    })
  }

  const reset = () => {
    user.value = {} as User;
    is.auth = false;
    is.init = false;
  };

  async function getUserList() {
    return apiUser.getList(query.value)
      .then(({ data }) => {
        if (query.value.page === 1) {
          userList.value = data;
        } else {
          userList.value = userList.value.concat(data)
        }

        isHasMore.value = !!data.length
      })
      .catch(() => {
        useNotificationStore().create({
          type: 'error',
          message: 'Что-то пошло не так',
        })
      })
  }


  return {
    is,
    user,
    ...getters,

    reset,
    setId,
    setAvatar,
    setIsAuth,
    setVerification,

    connectSocket,
    initUser,
    updateDataUser,
    sendPin,
    confirmPhone,
    logout,

    query,
    isHasMore,
    userList,
    getUserList,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
