import type { Timestamp } from '@/interfaces/common';
import { Account, AccountOperation, AccountType } from '../account';
import { TransactionMethodAdmin } from '../transaction';

export interface UserUpData {
  email?: string;
  role?: UserRole;
  name?: string;
  surname?: string;
  phone?: string;
  company?: string;
  comment?: string;
  country?: string;
  city?: string;
  address?: string;
  index?: number | string;
  site?: string;
  inn?: string;
  _id: string;
}

export interface UserUpDataBalance {
  balances?: string;
  amount?: number;
  type?: AccountType;
  operation?: AccountOperation;
  comment?: string;
  method?: TransactionMethodAdmin;
  _id: string;
}


export interface User extends Timestamp {
  email: string;
  role: UserRole; 
  avatar: string;
  verification: UserVerification;
  activated: boolean;
  moyskladId: string;
  region?: string;
  deliveryDay?: number;
  deliveryСity?: number;
  accounts?: Account[]
}

export interface UserVerification {
  status: UserVerificationStatus;
  name: string;
  surname: string;
  phone: string;
  company: string;
  comment: string;
  country: string;
  city: string;
  address: string;
  index: number | string;
  site: string;
  inn: string;
}

export enum UserRole {
  ADMIN = 'ADMIN',
  RECIPIENT = 'RECIPIENT',
  BROKER = 'BROKER',
  USER = 'USER',
}

export enum UserRoleName {
  ADMIN = 'Админ',
  RECIPIENT = 'Покупатель',
  BROKER = 'Брокер',
  USER = 'Плантация',
}

export enum UserVerificationStatus {
  INITIAL = 'INITIAL',
  WAITING = 'WAITING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export const UserRoleList = [
  { _id: UserRole.USER, name: UserRoleName.USER },
  { _id: UserRole.RECIPIENT, name: UserRoleName.RECIPIENT },
  { _id: UserRole.BROKER, name: UserRoleName.BROKER },
];

// DTO
export interface AuthSigninDto {
  email: string;
  password: string;
}

export interface AuthSignupDto extends AuthSigninDto {
  // role: UserRole;
}

export interface AuthSignupVerifyDto {
  token?: string;
}

export interface AuthRecoveryDto {
  email: string;
}

export interface AuthRecoveryVerifyDto {
  password: string;
  repeatPassword: string;
  token?: string;
}
