export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"type":"text/javascript","innerHTML":"\n            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n            m[i].l=1*new Date();\n            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n            (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n  \n            ym(99886115, \"init\", {\n              clickmap:true,\n              trackLinks:true,\n              accurateTrackBounce:true\n            });\n          "}],"noscript":[{"innerHTML":"\n            <div><img src=\"https://mc.yandex.ru/watch/99886115\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div>\n          "}],"htmlAttrs":{"lang":"ru"},"viewport":"width=device-width, initial-scale=1.0"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'