export default {
  catalog: 'Catalog',
  assortment: 'Assortment',
  transactions: 'Transactions',
  operationsHistory: 'Operations history',
  operationsProducts: 'Operations',
  transfers: 'Transfers',
  exchange: 'Exchange',
  auction: 'Auction',
  information: 'Information',
  verification: 'Verification',
  replenishments: 'Replenishments',
  topUpBalance: 'Top up balance',
  betweenYourAccounts: ' Between your accounts',
  nameless: 'Nameless',
  profile: 'Profile',
  historyOfOrders: 'History of orders',
  historyBalance: 'History of balance',
  logout: 'Logout',
  payments: 'Payments',
  acceptance: 'Acceptance',
  writeoff: 'Write-off',
  margin: 'Margin',
  delivery: 'Delivery',
  settings: 'Settings',
  region: 'Регион',
  users: 'Пользователи',
  rates: 'Rates',

  QRCODE: 'Tinkoff QR code',
  CRYPTO: 'Cryptocurrency',
  CASH: 'Cash payment',
  MANAGER: 'Added by manager',
  CARD: 'Card payment',
  BALANCE: 'Payment from balance',
  USER: 'Payment by user',
  ADMIN: 'Added by admin',
  CHECK: 'Payment by invoice',
  IN_PROCESSED: 'In progress',
  REJECTED: 'Canceled',
  CONFIRMED: 'Replenished',
  CONCLUSION: 'Written off',
  COMPENSATION: 'Compensation',

}
